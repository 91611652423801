
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";

import { Reservation, Status } from "../store";

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    ReservationEditForm: () => import("@/app/modules/booking/components/ReservationEditForm.vue")
  }
})
export default class EditReservationPage extends Vue {
  @Prop(Number) readonly id!: number;

  @Watch("$route.query")
  onRouteQueryChanged() {
    this.fetchReservation();
  }

  valid: boolean = true as boolean;
  errors: object = {} as object;

  formData: Reservation | null = null;
  cancelDialog = false;

  rules = {
    required: Rules.required,
    date: Rules.date
  };

  beforeDestroy() {
    this.scrollTop();
  }

  created() {
    this.fetchReservation();
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get canBeUpdated(): boolean {
    if (this.formData == null) return false;

    return ![Status.Occupied, Status.Cancelled, Status.Completed].includes(this.formData.status.id);
  }

  get canBeCancelled(): boolean {
    if (this.formData == null) return false;

    return ![Status.Cancelled, Status.Completed].includes(this.formData.status.id);
  }

  fetchReservation() {
    const data = {
      person_id: this.userData.person.id,
      reservation_id: this.id
    };

    this.$store.dispatch("booking/fetchReservation", data).then(({ data }) => {
      this.formData = { ...data };
    });
  }

  cancel() {
    this.$store
      .dispatch("booking/cancelReservation", this.formData?.id)
      .then(() => this.$router.push({ name: "booking.reservations" }))
      .catch(error => {
        this.cancelDialog = false;
        this.errors = error.response.data.errors;
      });
  }

  update() {
    const data = {
      id: this.formData?.id,
      body: {
        person_id: this.userData.person.id,
        status_id: this.formData?.status.id,
        number_of_visitors: this.formData?.number_of_visitors,
        date_from: this.formData?.date_from,
        date_to: this.formData?.resource.type_id == 1 ? this.formData?.date_to : undefined,
        time_from: this.formData?.time_from,
        time_to: this.formData?.time_to,
        info: this.formData?.info
      }
    };

    this.$store
      .dispatch("booking/updateReservation", data)
      .then(() => this.$router.push({ name: "booking.reservations" }))
      .catch(error => (this.errors = error.response.data.errors));
  }

  scrollTop() {
    const card = document.getElementById("card");
    if (card) card.scrollIntoView({ behavior: "smooth" });
  }
}
